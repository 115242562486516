import React from 'react'
import {buildImageObj} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'

import useLegendImg from '../hooks/useLegendImg'

import styles from './legendImg.module.css'

export default () => {
    const {mainImage} = useLegendImg()
    return (
        <div className="flex-1">
            <div className={styles.mainImage}>
            {mainImage && mainImage.asset && (
            
                <img
                src={imageUrlFor(buildImageObj(mainImage))
                    .width(600)
                    .height(Math.floor((9 / 16) * 600))
                    .fit('crop')
                    .auto('format')
                    .url()}
                alt={mainImage.alt}
                />
            
            )}
            </div>
        </div>
    )
}