import { useStaticQuery, graphql } from "gatsby"

const useHomeContent = () => {
  const data = useStaticQuery(graphql`
  {
    sanityPage(slug: {current: {eq: "home"}}) {
      _id
      _rawBody
      mainImage {
        ...SanityImage
        alt
      }
      slug {
        current
      }
      title
    }
  }
`)
  return data.sanityPage
}

export default useHomeContent