import { useStaticQuery, graphql } from "gatsby"

const useRtCtaPanel = () => {
  const data = useStaticQuery(graphql`
  {
    sanityCtaPanels(slug: {current: {eq: "auto-service"}}) {
      title
      mainImage {
        ...SanityImage
        alt
      }
      link
    }
  }
`)
  return data.sanityCtaPanels
}

export default useRtCtaPanel