import React from 'react'
import {buildImageObj} from '../../lib/helpers'
import {imageUrlFor} from '../../lib/image-url'

import useLeftCtaPanel from '../../hooks/useRtCtaPanel'

import { TiChevronRight } from "react-icons/ti"

export default () => {
    const {mainImage, title, link} = useLeftCtaPanel()
    return (
        <div className="w-full flex m-auto sm:mx-4">
            <div className="flex">
            {mainImage && mainImage.asset && (
          
            <img
                src={imageUrlFor(buildImageObj(mainImage))}
                className="w-24 h-24 w-full object-contain self-center"
                alt={mainImage.alt}
            />
        
            )}
            </div>

            <div className="pr-2">
                <div className="m-4">
                    <div className="text-base lg:text-base xl:text-xl text-white font-bold">
                        {title}
                    </div>
            
                    <p className="text-white text-sm md:text-base lg:text-xs xl:text-base">
                        <a href={link}>Learn More <TiChevronRight /></a>
                    </p>
                </div>
            </div>
        </div>
        )
  }