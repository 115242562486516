import React from 'react'
import {buildImageObj} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'

import useHomeContent from '../hooks/useHomeContent'

export default () => {
    const {mainImage} = useHomeContent()
    return (
        <div className="w-full">
            {mainImage && mainImage.asset && (
            
                <img
                src={imageUrlFor(buildImageObj(mainImage))}
                className="sm:object-contain md:object-contain lg:object-cover object-top h-100 w-full"
                alt={mainImage.alt}
                />
            
            )}
        </div>
    )
}