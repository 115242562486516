import React from 'react'
import PortableText from './portableText'

import useHomeContent from '../hooks/useHomeContent'

export default () => {
    const {_rawBody} = useHomeContent()
    return <div className="flex-1 self-center px-5 pt-5 lg:px-5 lg:pt-0 text-white text-base sm:text-xs md:text-sm lg:text-sm xl:text-xl self-center">
                {_rawBody && <PortableText blocks={_rawBody} />}
            </div>
           
}