import React from 'react'

import LeftCtaPanel from './panels/leftCtaPanel'
import CntrCtaPanel from './panels/cntrCtaPanel'
import RtCtaPanel from './panels/rtCtaPanel'

export default () => {
    return (
        <div className="lg:flex lg:flex-row cta-panels sm:relative lg:absolute p-0 md:pb-2 lg:-mt-40">
            <div className="w-full lg:w-1/3 bg-black lg:rounded-lg lg:opacity-75 lg:mx-2 md:py-4 lg:py-0">
                <LeftCtaPanel />
            </div>

            <div className="w-full lg:w-1/3 bg-black lg:rounded-lg lg:opacity-75 lg:mr-2 md:pb-2 lg:py-0">
                <CntrCtaPanel />
            </div>

            <div className="w-full lg:w-1/3 bg-black lg:rounded-lg lg:opacity-75 lg:mr-2 md:py-2 lg:py-0">
                <RtCtaPanel />
            </div>
        </div>
    )
}