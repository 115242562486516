import { useStaticQuery, graphql } from "gatsby"

const useLegendImg = () => {
  const data = useStaticQuery(graphql`
  {
    sanityPageBanner(slug: {current: {eq: "legend"}}) {
      mainImage {
        ...SanityImage
        alt
      }
      title
    }
  }
`)
  return data.sanityPageBanner
}

export default useLegendImg